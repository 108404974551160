import React from 'react';
import loadable from '@loadable/component';
import { LinearProgress } from '@mui/material';

export const App = loadable(() => import('../../App'), {
  fallback: <LinearProgress />,
});

export const MainPage = loadable(() => import('../../main/MainPage'), {
  fallback: <LinearProgress />,
});

export const LoginPage = loadable(() => import('../../login/LoginPage'), {
  fallback: <LinearProgress />,
});

export const RegisterPage = loadable(() => import('../../login/RegisterPage'), {
  fallback: <LinearProgress />,
});

export const ResetPasswordPage = loadable(() => import('../../login/ResetPasswordPage'), {
  fallback: <LinearProgress />,
});

export const ChangeServerPage = loadable(() => import('../../other/ChangeServerPage'), {
  fallback: <LinearProgress />,
});

export const PositionPage = loadable(() => import('../../other/PositionPage'), {
  fallback: <LinearProgress />,
});

export const NetworkPage = loadable(() => import('../../other/NetworkPage'), {
  fallback: <LinearProgress />,
});

export const EventPage = loadable(() => import('../../other/EventPage'), {
  fallback: <LinearProgress />,
});

export const ReplayPage = loadable(() => import('../../other/ReplayPage'), {
  fallback: <LinearProgress />,
});

export const GeofencesPage = loadable(() => import('../../other/GeofencesPage'), {
  fallback: <LinearProgress />,
});

export const AccumulatorsPage = loadable(() => import('../../settings/AccumulatorsPage'), {
  fallback: <LinearProgress />,
});

export const CalendarsPage = loadable(() => import('../../settings/CalendarsPage'), {
  fallback: <LinearProgress />,
});

export const CalendarPage = loadable(() => import('../../settings/CalendarPage'), {
  fallback: <LinearProgress />,
});

export const CommandsPage = loadable(() => import('../../settings/CommandsPage'), {
  fallback: <LinearProgress />,
});

export const CommandPage = loadable(() => import('../../settings/CommandPage'), {
  fallback: <LinearProgress />,
});

export const CommandSendPage = loadable(() => import('../../settings/CommandSendPage'), {
  fallback: <LinearProgress />,
});

export const ComputedAttributesPage = loadable(() => import('../../settings/ComputedAttributesPage'), {
  fallback: <LinearProgress />,
});

export const ComputedAttributePage = loadable(() => import('../../settings/ComputedAttributePage'), {
  fallback: <LinearProgress />,
});

export const DevicePage = loadable(() => import('../../settings/DevicePage'), {
  fallback: <LinearProgress />,
});

export const DevicesPage = loadable(() => import('../../settings/DevicesPage'), {
  fallback: <LinearProgress />,
});

export const DriversPage = loadable(() => import('../../settings/DriversPage'), {
  fallback: <LinearProgress />,
});

export const DriverPage = loadable(() => import('../../settings/DriverPage'), {
  fallback: <LinearProgress />,
});

export const GeofencePage = loadable(() => import('../../settings/GeofencePage'), {
  fallback: <LinearProgress />,
});

export const GroupsPage = loadable(() => import('../../settings/GroupsPage'), {
  fallback: <LinearProgress />,
});

export const GroupPage = loadable(() => import('../../settings/GroupPage'), {
  fallback: <LinearProgress />,
});

export const MaintenancesPage = loadable(() => import('../../settings/MaintenancesPage'), {
  fallback: <LinearProgress />,
});

export const MaintenancePage = loadable(() => import('../../settings/MaintenancePage'), {
  fallback: <LinearProgress />,
});

export const NotificationsPage = loadable(() => import('../../settings/NotificationsPage'), {
  fallback: <LinearProgress />,
});

export const NotificationPage = loadable(() => import('../../settings/NotificationPage'), {
  fallback: <LinearProgress />,
});

export const PreferencesPage = loadable(() => import('../../settings/PreferencesPage'), {
  fallback: <LinearProgress />,
});

export const ServerPage = loadable(() => import('../../settings/ServerPage'), {
  fallback: <LinearProgress />,
});

export const UsersPage = loadable(() => import('../../settings/UsersPage'), {
  fallback: <LinearProgress />,
});

export const UserPage = loadable(() => import('../../settings/UserPage'), {
  fallback: <LinearProgress />,
});

export const SynopticMapPage = loadable(() => import('../../settings/SynopticMapPage'), {
  fallback: <LinearProgress />,
});

export const BusStopPage = loadable(() => import('../../settings/BusStopPage'), {
  fallback: <LinearProgress />,
});

export const BusStopsPage = loadable(() => import('../../settings/BusStopsPage'), {
  fallback: <LinearProgress />,
});

export const ChartReportPage = loadable(() => import('../../reports/ChartReportPage'), {
  fallback: <LinearProgress />,
});

export const EventReportPage = loadable(() => import('../../reports/EventReportPage'), {
  fallback: <LinearProgress />,
});

export const RouteReportPage = loadable(() => import('../../reports/RouteReportPage'), {
  fallback: <LinearProgress />,
});

export const StatisticsPage = loadable(() => import('../../reports/StatisticsPage'), {
  fallback: <LinearProgress />,
});

export const StopReportPage = loadable(() => import('../../reports/StopReportPage'), {
  fallback: <LinearProgress />,
});

export const SummaryReportPage = loadable(() => import('../../reports/SummaryReportPage'), {
  fallback: <LinearProgress />,
});

export const TripReportPage = loadable(() => import('../../reports/TripReportPage'), {
  fallback: <LinearProgress />,
});
