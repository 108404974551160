import { loadImage, prepareIcon } from './mapUtil';

import directionSvg from '../../resources/images/direction.svg';
import backgroundSvg from '../../resources/images/background.svg';
import markerSvg from '../../resources/images/arrow.svg';
import animalSvg from '../../resources/images/icon/animal.svg';
import bicycleSvg from '../../resources/images/icon/bicycle.svg';
import boatSvg from '../../resources/images/icon/boat.svg';
import busSvg from '../../resources/images/icon/bus.svg';
import carSvg from '../../resources/images/icon/car.svg';
import craneSvg from '../../resources/images/icon/crane.svg';
import defaultgreySvg from '../../resources/images/defaultgrey.svg';
import defaultSvg from '../../resources/images/default.svg';
import defaultgreenSvg from '../../resources/images/defaultgreen.svg';
import defaultredSvg from '../../resources/images/defaultred.svg';
import defaultstopSvg from '../../resources/images/defaultstop.svg';
import defaultgeofenceSvg from '../../resources/images/defaultgeofence.svg';
import busstopSvg from '../../resources/images/icon/busstop.svg';
import helicopterSvg from '../../resources/images/icon/helicopter.svg';
import motorcycleSvg from '../../resources/images/icon/motorcycle.svg';
import offroadSvg from '../../resources/images/icon/offroad.svg';
import personSvg from '../../resources/images/icon/person.svg';
import pickupSvg from '../../resources/images/icon/pickup.svg';
import planeSvg from '../../resources/images/icon/plane.svg';
import scooterSvg from '../../resources/images/icon/scooter.svg';
import shipSvg from '../../resources/images/icon/ship.svg';
import tractorSvg from '../../resources/images/icon/tractor.svg';
import trainSvg from '../../resources/images/icon/train.svg';
import tramSvg from '../../resources/images/icon/tram.svg';
import trolleybusSvg from '../../resources/images/icon/trolleybus.svg';
import truckSvg from '../../resources/images/icon/truck.svg';
import vanSvg from '../../resources/images/icon/van.svg';

export const mapIcons = {
  animal: animalSvg,
  bicycle: bicycleSvg,
  boat: boatSvg,
  bus: busSvg,
  busstop: busstopSvg,
  car: carSvg,
  crane: craneSvg,
  defaultgreen: defaultgreenSvg,
  defaultstop: defaultstopSvg,
  defaultgeofence: defaultgeofenceSvg,
  default: defaultSvg,
  defaultgrey: defaultgreySvg,
  defaultred: defaultredSvg,
  helicopter: helicopterSvg,
  motorcycle: motorcycleSvg,
  offroad: offroadSvg,
  person: personSvg,
  pickup: pickupSvg,
  plane: planeSvg,
  scooter: scooterSvg,
  ship: shipSvg,
  tractor: tractorSvg,
  train: trainSvg,
  tram: tramSvg,
  trolleybus: trolleybusSvg,
  truck: truckSvg,
  van: vanSvg,
};

const defaultColors = ['primary', 'positive', 'negative', 'neutral', 'stop', 'geofence'];

export const mapIconKey = (category) => (mapIcons.hasOwnProperty(category) ? category : 'default');

export const mapImages = {};

const getImage = async (key) => {
  let imagePath = mapIcons.defaultgrey;
  switch (key) {
    case 'primary':
      imagePath = mapIcons.default;
      break;
    case 'positive':
      imagePath = mapIcons.defaultgreen;
      break;
    case 'negative':
      imagePath = mapIcons.defaultred;
      break;
    case 'neutral':
      imagePath = mapIcons.defaultgrey;
      break;
    case 'stop':
      imagePath = mapIcons.defaultstop;
      break;
    case 'geofence':
      imagePath = mapIcons.defaultgeofence;
      break;
    default:
      imagePath = mapIcons.defaultred;
      break;
  }
  return loadImage(imagePath);
};

export const createGeneratedColor = async (key) => {
  const color = key.replace('default-', '');
  if (color !== '-11' && color !== 'convenience-11' && !defaultColors.includes(color)) {
    const { default: customSvg } = await import(`../../resources/images/generated/${color}.svg`);
    const imageBackground = await loadImage(customSvg);
    return prepareIcon(imageBackground, undefined);
  }
  return false;
};

export default async () => {
  const background = await loadImage(backgroundSvg);
  const busstop = await loadImage(busstopSvg);
  const marker = await loadImage(markerSvg);
  mapImages.background = await prepareIcon(background);
  mapImages.busstop = await prepareIcon(busstop);
  mapImages.marker = await prepareIcon(marker);
  mapImages.direction = await prepareIcon(await loadImage(directionSvg));
  await Promise.all(Object.keys(mapIcons).map(async (category) => {
    const results = [];
    defaultColors.forEach((color) => {
      results.push(loadImage(mapIcons[category]).then(async (icon) => {
        const imageBackground = await getImage(color);
        mapImages[`${category}-${color}`] = prepareIcon(imageBackground, icon);
      }));
    });
    await Promise.all(results);
  }));
};
